<template>
  <div class="height-100 max_box">
    <div class="audio-box">
      <audio src="audio/bg.mp3" ref="bgAudio"></audio>
      <audio :src="audioSrcList[0]" ref="audio1"></audio>
      <audio :src="audioSrcList[1]" ref="audio2"></audio>
      <audio :src="audioSrcList[2]" ref="audio3"></audio>
      <audio :src="audioSrcList[3]" ref="audio4"></audio>
      <audio :src="audioSrcList[4]" ref="audio5"></audio>
      <audio :src="audioSrcList[5]" ref="audio6"></audio>
    </div>
    <div class="card animate__animated animate__fadeIn duration" id="card1">
      <div class="content_box">
        <div class="card1_top_box">
          <p class="font-blod">两融日报</p>
          <p v-if="extra_param_1[0]">
            {{ formatToDate(extra_param_1[0], "-") }}
          </p>
        </div>
        <div class="logo-box">
          <img src="../assets/img/tanzhiwenlogo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="content_box">
        <div class="title">
          <p class="title_l">两融趋势</p>
          <p class="title_r_1">
            <span>{{ formatToDate(extra_param_1[0], ".") }}</span>
          </p>
        </div>
        <p class="desc">
          今日，两市融资融券余额<span class="color_gold num_width" id="num1"
            >{{ extra_param_1[1] }} </span
          ><span class="color_gold">亿</span>，较上日{{ extra_param_1[2] }}
          <span
            :class="{
              color_gold: extra_param_1[2] === '上涨',
              color_green: extra_param_1[2] === '下降',
            }"
            >{{ extra_param_1[3] }}%</span
          >,融资买入<span id="num2" class="color_gold num_width2">{{
            extra_param_1[4]
          }}</span
          ><span class="color_gold">亿</span>，较上日{{ extra_param_1[5]
          }}<span
            :class="{
              color_gold: extra_param_1[5] === '上涨',
              color_green: extra_param_1[5] === '下降',
            }"
            >{{ extra_param_1[6] }}％</span
          >
        </p>
        <div class="title">
          <p class="title_1">
            {{ chart_data_1[0] && chart_data_1[0].model_chart_name }}（亿元）
          </p>
        </div>
        <div id="chart1"></div>
        <div class="title">
          <p class="title_1">
            {{ chart_data_2[0] && chart_data_2[0].model_chart_name }}（亿元）
          </p>
        </div>
        <div id="chart1_2"></div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="content_box">
        <div class="title">
          <p class="title_l">板块风云榜 | 行业板块</p>
          <p class="title_r_1">{{ formatToDate(extra_param_1[0], ".") }}</p>
        </div>
        <div class="rank_list">
          <ul>
            <li
              v-for="(item, index) in ccb1"
              :key="index"
              :class="`card-li-${Math.floor(index / 4)}`"
            >
              <div class="text1">{{ item }}</div>
              <div class="text2">{{ udfs[index] }}亿</div>
            </li>
          </ul>
        </div>
        <div class="slider">
          <span>低</span>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <span>高</span>
        </div>
        <div class="tips">
          风险提示：本视频相关内容不对各位读者构成任何投资建议，据此操作，风险自担
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="content_box">
        <div class="title">
          <p class="title_l">{{ model_chart_name_4 }}</p>
          <p class="title_r_1">
            <span
              ><i>{{ formatToDate(extra_param_1[0], ".") }}</i></span
            >
          </p>
        </div>
        <p class="desc">
          从个券来看，当日融资净买入额最多的个股为{{
            extra_param_3[0]
          }}，融资净买入额为
          <span class="color_gold" id="num3">{{ extra_param_3[1] }}</span
          >亿
        </p>
        <div class="desc_title_wrapper">
          <div class="desc_title1">融资净买入额（亿元）</div>
          <div class="desc_title2">涨跌幅%</div>
        </div>
        <div class="desc_values_wrapper">
          <div
            class="desc_values_item"
            v-for="(value, index) in chart4.change"
            :key="index"
          >
            <span
              :class="{
                color_gold: Math.sign(value) == 1,
                color_green: Math.sign(value) == -1,
              }"
            >
              {{ value }}</span
            >
          </div>
        </div>
        <div id="chart4"></div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="content_box">
        <div class="title">
          <p class="title_l">{{ model_chart_name_5 }}</p>
          <p class="title_r_1">
            <span
              ><i>{{ formatToDate(extra_param_1[0], ".") }}</i></span
            >
          </p>
        </div>
        <p class="desc">
          从个券来看，当日融资净卖出最多的个股为{{
            extra_param_4[0]
          }}，融资净买入额为<span class="color_green" id="num4">{{
            extra_param_4[1]
          }}</span
          >亿
        </p>
        <div class="desc_title_wrapper">
          <div class="desc_title1">融资净买入额（亿元）</div>
          <div class="desc_title2">涨跌幅%</div>
        </div>
        <div class="desc_values_wrapper">
          <div
            class="desc_values_item"
            v-for="(value, index) in chart5.change"
            :key="index"
          >
            <span
              :class="{
                color_gold: Math.sign(value) == 1,
                color_green: Math.sign(value) == -1,
              }"
            >
              {{ value }}</span
            >
          </div>
        </div>
        <div id="chart5"></div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="content_box">
        <div class="title">
          <p class="title_l">{{ model_chart_name_6 }}</p>
          <p class="title_r_1">
            <span
              ><i>{{ formatToDate(extra_param_1[0], ".") }}</i></span
            >
          </p>
        </div>
        <div class="desc_title_wrapper">
          <div class="desc_title1">净买入额占比%</div>
          <div class="desc_title2">净买入额(亿元)</div>
          <div class="desc_title3">涨跌幅</div>
        </div>
        <div class="desc_values_wrapper">
          <div
            class="desc_values_item"
            v-for="(value, index) in chart6.change"
            :key="index"
          >
            <span
              :class="{
                color_gold: Math.sign(value) == 1,
                color_green: Math.sign(value) == -1,
              }"
              class="desc_values_width1"
              >{{ value }}%</span
            >
            <span
              :class="{
                color_gold: Math.sign(value) == 1,
                color_green: Math.sign(value) == -1,
              }"
              class="desc_values_width"
            >
              {{ chart6.buy_in[index] }}</span
            >
          </div>
        </div>
        <div id="chart6"></div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card7"
    >
      <div class="content_box">
        <div class="title">
          <p class="title_l">融资追击</p>
          <p class="title_r_1">
            <span
              ><i>{{ formatToDate(extra_param_1[0], ".") }}</i></span
            >
          </p>
        </div>
        <div class="desc_title_wrapper7">
          <div class="desc_title1">股票名称</div>
          <div class="desc_title2">融资买入比</div>
          <div class="desc_title2">持股周期</div>
          <div class="desc_title3">胜率</div>
          <div class="desc_title3">获利空间</div>
        </div>
        <div class="desc_values_wrapper7">
          <div
            class="desc_values_item7"
            v-for="(item, index) in chart7.sBuyPercent"
            :key="'sBuyPercent' + index"
          >
            <span class="span_width1">{{ chart7.sSecName[index] }} </span>
            <span class="span_width"> {{ chart7.sBuyPercent[index] }}</span>
            <span class="span_width2"> {{ chart7.iHoldDays[index] }}天</span>
            <span class="span_width2 color_gold">
              {{ chart7.fWinPer[index] }}%</span
            >
            <span class="span_width2 color_gold">
              {{ chart7.fIncRateAvg[index] }}%
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card8"
    >
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/huaanend.mov"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams, formatToDate } from "../utils/common";
import ApiServe from "@/api/index";
export default {
  name: "Template51",
  data() {
    return {
      model_id: 51,
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      options: {
        useEasing: true, // 过渡动画效果，默认ture
        useGrouping: false, // 千分位效果，例：1000->1,000。默认true
        //separator: ',',   // 使用千分位时分割符号
        decimals: 2, // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "", // 后置符号，可汉字
      },
      extra_param_1: [],
      extra_param_2: [],
      extra_param_3: [],
      extra_param_4: [],
      chart_data_1: [],
      chart_data_2: [],
      chart_data_3: [],
      chart_data_4: [],
      chart_data_5: [],
      chart_data_6: [],
      model_chart_name: "",
      model_chart_name_4: "",
      model_chart_name_5: "",
      model_chart_name_6: "",
      udfs: "",
      ccb1: "",
      buy_in: [],
      min_chart1: "",
      max_chart1: "",
      min_chart1_2: "",
      max_chart1_2: "",
      chart1: {
        x: [],
        y: [],
      },
      chart1_2: {
        x: [],
        y: [],
      },
      chart4: { sec_name: [], change: [], buy_in: [] },
      chart5: { sec_name: [], change: [], buy_in: [] },
      chart6: { sec_name: [], change: [], buy_in: [], ym: [] },
      chart7: {
        iHoldDays: [],
        sBuyPercent: [],
        fWinPer: [],
        fIncRateAvg: [],
        sSecName: [],
        sDtSecCode: [],
      },
      recordtimeAll: null,
    };
  },
  created() {
    this.device_id = GetParams().device_id || null;
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { extra_param_1, audioSrcList } = this;
      return {
        extra_param_1,
        audioSrcList,
      };
    },
  },
  watch: {
    allReady(val) {
      if (val.extra_param_1.length > 0 && val.audioSrcList.length > 0) {
        if (this.device_id) {
          setTimeout(() => {
            ApiServe.recordStart({
              model_id: this.model_id,
              device_id: this.device_id,
              record_time: this.recordtimeAll,
              real: 1,
            }).then((res) => {
              this.load();
            });
          }, 5000);
        } else {
          setTimeout(() => {
            this.load();
          }, 3000);
        }
      }
    },
  },
  methods: {
    formatToDate,
    getData() {
      ApiServe.getData({ model_id: this.model_id })
        .then(({ data }) => {
          try {
            this.extra_param_1 = data.extra_param_1;
            this.chart_data_1 = data.chart_data_1;
            this.chart_data_2 = data.chart_data_2;
            this.chart_data_3 = data.chart_data_3[0];
            this.ccb1 = this.chart_data_3.data["ccb1"].reverse();
            this.udfs =
              this.chart_data_3.data["udfs.round2(dwwc, 2)"].reverse();

            this.chart1 = {
              x: data.chart_data_1[0].data.x,
              y: data.chart_data_1[0].data.y,
            };
            this.max_chart1 = Math.max.apply(null, this.chart1.y);
            this.min_chart1 = Math.min.apply(null, this.chart1.y);

            this.chart1_2 = {
              x: data.chart_data_2[0].data.x,
              y: data.chart_data_2[0].data.y,
            };
            this.max_chart1_2 = Math.max.apply(null, this.chart1_2.y);
            this.min_chart1_2 = Math.min.apply(null, this.chart1_2.y);

            // chart4
            const chart4Data = data.chart_data_4[0].data;
            this.chart4 = {
              sec_name: chart4Data.sec_name.slice(0, 10),
              change: chart4Data["涨跌幅"].slice(0, 10),
              buy_in: chart4Data["融资净买入额"].slice(0, 10),
            };
            this.model_chart_name_4 = data.chart_data_4[0].model_chart_name;

            // chart5
            const chart5Data = data.chart_data_5[0].data;
            this.chart5 = {
              sec_name: chart5Data.sec_name.slice(0, 10),
              change: chart5Data["涨跌幅"].slice(0, 10),
              buy_in: chart5Data["融资净买入额"].slice(0, 10),
            };
            this.model_chart_name_5 = data.chart_data_5[0].model_chart_name;

            // chart6
            const chart6Data = data.chart_data_6[0].data;
            this.chart6 = {
              sec_name: chart6Data.sec_name.slice(0, 10),
              change: chart6Data["涨跌幅"].slice(0, 10),
              ym: chart6Data["ym"].slice(0, 10), // %
              buy_in: chart6Data["融资净买入额"].slice(0, 10),
            };
            this.model_chart_name_6 = data.chart_data_6[0].model_chart_name;

            //card 7

            this.getRZ(data.chart_data_7[1].url).then(() => {
              this.getGp(data.chart_data_7[0].url);
            });

            this.extra_param_2 = data.extra_param_2;
            this.extra_param_3 = data.extra_param_3;
            this.extra_param_4 = data.extra_param_4;
          } catch (error) {
            console.log(error);
          }
        })
        .catch((err) => {
          if (this.device_id) {
            ApiServe.releasedSevice({ device_id: this.device_id });
          }
        });
    },

    //获取股票名称
    async getGp(url, params) {
      params = this.chart7.sDtSecCode;
      // console.log(this.chart7.sDtSecCode);
      // console.log(params);
      let { content, ret } = await this.$axios.post(
        url,
        {
          vDtSecCode: params,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (ret == 0) {
        // console.log(content);
        const vSecSimpleQuote = JSON.parse(content).vSecSimpleQuote;
        vSecSimpleQuote.map((item) => {
          item.sSecName;
          this.chart7.sSecName.push(item.sSecName);
        });
      }
    },

    //获取融资追击信息
    async getRZ(url) {
      // params = {
      //   stUserInfo: {
      //     vGUID: [
      //       -51, 43, -37, -70, 28, -125, -33, 14, -31, -91, 65, 44, 23, 42, 59,
      //       -29,
      //     ],
      //     sDUA: "SN=IOSCJPH33_FA&VN=338033014&BN=0&VC=APPLE&MO=iPhone&RL=414_896&CHID=1000&LCID=0&RV=&OS=12.1.4&DV=V1",
      //     iAccountId: 1362106,
      //     sIMEI: "4DBE6036-F11B-4A54-908A-8FF2B8F6320D",
      //   },
      // };
      let { content, ret } = await this.$axios.post(url, null, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (ret == 0) {
        const vtWinBuySignalRst = JSON.parse(content).vtWinBuySignalRst;
        // console.log(vtIncBuySignalRst);
        // console.log(vtIncBuySignalRst);s

        JSON.parse(content)
          .vtWinBuySignalRst.sort(
            (a, b) =>
              b.stStockBackTraceRst.fWinPer - a.stStockBackTraceRst.fWinPer
          )
          .forEach((item, index) => {
            // console.log(item.stMarginTradeDt.sBuyPercent); //sBuyPercent
            // console.log(item.stStockBackTraceRst.fIncRateAvg); //胜率：
            // console.log(item.stStockBackTraceRst.fWinPer); //获利空间
            // console.log(item.stStockBackTraceRst.iHoldDays); //
            // console.log(item.stStockBackTraceRst.sDtSecCode);
            this.chart7.sBuyPercent.push(item.stMarginTradeDt.sBuyPercent);
            // console.log(this.chart7.sBuyPercent);
            this.chart7.fIncRateAvg.push(item.stStockBackTraceRst.fIncRateAvg);
            this.chart7.fWinPer.push(item.stStockBackTraceRst.fWinPer);
            this.chart7.iHoldDays.push(item.stStockBackTraceRst.iHoldDays);
            this.chart7.sDtSecCode.push(item.stStockBackTraceRst.sDtSecCode);
          });
      }
    },
    // 获取旁白
    getAudio() {
      ApiServe.getVideoData({ model_id: this.model_id })
        .then(({ data, code }) => {
          if (code == 200) {
            this.audioSrcList = data.map((item) => {
              return item.audio_link;
            });
            this.audioTimeList = data.map((item) => {
              return item.scene_duration;
            });
            this.recordtimeAll =
              this.audioTimeList.reduce(function (prev, curr, idx, arr) {
                return prev + curr;
              }) +
              this.audioTimeList.length +
              3;
          }
        })
        .catch((err) => {
          if (this.device_id) {
            ApiServe.releasedSevice({ device_id: this.device_id });
          }
        });
    },
    // 数字滚动
    startNumber(vdom, number, point) {
      let num = new CountUp(vdom, 0, number, point, 4, this.options);
      num.start();
    },
    // 融资融券余额（亿元）
    loadChart1() {
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chart1.x,
          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLabel: {
            align: "left",
            textStyle: {
              fontSize: 10,
              color: "#4D4D4D",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#4D4D4D",
              width: 0.3,
            },
          },
        },
        legend: {
          data: [],
          left: 0,
        },
        animationDuration: 4000,
        grid: {
          top: 13,
          left: 9,
          right: 50,
          bottom: 25,
          show: true,
          borderWidth: "0.3",
          color: ["#FFD2AC"],
        },
        yAxis: [
          {
            type: "value",
            name: "",
            boundaryGap: [0, "100%"],
            position: "right",
            min: this.min_chart1,
            max: this.max_chart1,
            axisLabel: {
              textStyle: {
                fontSize: 10,
                color: "#4D4D4D",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: ["#A1AECB"],
              },
            },
            axisLine: {
              lineStyle: {
                color: "#A1AECB",
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: 0.6,
            symbol: "none",
            lineStyle: {
              color: "#5470C6",
              width: 1,
            },
            color: "#F68630",
            data: this.chart1.y,
            label: {
              show: true,
            },
            areaStyle: {
              color: "#7464DF",
            },
          },
        ],
        markLine: {
          symbol: ["none", "none"],
          label: { show: false },
          data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }],
        },
      });
    },
    // 融资买入额 （亿元）
    loadChart1_2() {
      let myChart = this.$echarts.init(document.getElementById("chart1_2"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chart1_2.x,
          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLabel: {
            align: "left",
            textStyle: {
              fontSize: 10,
              color: "#4D4D4D",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#4D4D4D",
              width: 0.3,
            },
          },
        },
        legend: {
          data: [],
          left: 0,
        },
        animationDuration: 4000,
        grid: {
          top: 13,
          left: 9,
          right: 50,
          bottom: 25,
          show: true,
          borderWidth: "0.3",
          color: ["#FFD2AC"],
        },
        yAxis: [
          {
            type: "value",
            name: "",
            boundaryGap: [0, "100%"],
            position: "right",
            min: this.min_chart1_2,
            max: this.max_chart1_2,
            axisLabel: {
              textStyle: {
                fontSize: 10,
                color: "#4D4D4D",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: ["#A1AECB"],
              },
            },
            axisLine: {
              lineStyle: {
                color: "#A1AECB",
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: 0.6,
            symbol: "none",
            lineStyle: {
              color: "#5470C6",
              width: 1,
            },
            color: "#F68630",
            data: this.chart1_2.y,
            label: {
              show: true,
            },
            areaStyle: {
              color: "#7464DF",
            },
          },
        ],
        markLine: {
          symbol: ["none", "none"],
          label: { show: false },
          data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }],
        },
      });
    },

    // 融资净买入
    loadChart4() {
      var myChart = this.$echarts.init(document.getElementById("chart4"));
      myChart.setOption({
        grid: {
          left: "70",
          right: "60",
          bottom: "0",
          top: 0,
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            max: 10,
            show: true,
            axisLine: { show: false },
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            inverse: true,
            type: "category",
            data: this.chart4["sec_name"],
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              textStyle: {
                fontSize: 14,
                color: "#6D6D6D",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.chart4.buy_in,
            barWidth: 16,
            backgroundStyle: {
              color: "#F1F2F8",
            },
            itemStyle: {
              normal: {
                color: "#5969DC",
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#6063F1",
                    fontSize: 12,
                  },
                },
              },
            },
            showBackground: true,
          },
        ],
      });
    },
    // 融资净卖出
    loadChart5() {
      var myChart = this.$echarts.init(document.getElementById("chart5"));
      myChart.setOption({
        grid: {
          left: "70",
          right: "40",
          bottom: "0",
          top: 0,
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            max: 10,
            show: true,
            axisLine: { show: false },
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: this.chart5["sec_name"],
            axisLine: { show: false, onZero: true },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              textStyle: {
                fontSize: 14,
                color: "#6D6D6D",
              },
            },
          },
        ],
        series: [
          {
            inverse: true,
            type: "bar",
            data: this.chart5.buy_in.map((item) => {
              return Math.abs(item);
            }),
            barWidth: 16,
            backgroundStyle: {
              color: "#F1F2F8",
            },
            itemStyle: {
              normal: {
                color: "#439679",
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  formatter: function (value) {
                    return "-" + value.data;
                  },
                  textStyle: {
                    //数值样式
                    color: "#439679",
                    fontSize: 12,
                  },
                },
              },
            },
            showBackground: true,
          },
        ],
      });
    },
    // 行业盈利与估值对比散点图
    loadChart6() {
      var myChart = this.$echarts.init(document.getElementById("chart6"));
      myChart.setOption({
        grid: {
          left: "70",
          right: "140",
          bottom: "0",
          top: 0,
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            max: 100,
            show: true,
            axisLine: { show: false },
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            inverse: true,
            type: "category",
            data: this.chart6["sec_name"],
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              textStyle: {
                fontSize: 14,
                color: "#6D6D6D",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.chart6.ym,
            barWidth: 15,
            backgroundStyle: {
              color: "#F1F2F8",
            },
            itemStyle: {
              normal: {
                color: "#5969DC",
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#6063F1",
                    fontSize: 12,
                  },
                },
              },
            },
            showBackground: true,
          },
        ],
      });
    },
    load() {
      this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 500);

      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.$refs.audio1.play();
        this.startNumber("num1", this.extra_param_1[1], 2);
        this.startNumber("num2", this.extra_param_1[4], 2);
        this.loadChart1();
        this.loadChart1_2();
      }, 2000); //间隔

      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, (2 + this.audioTimeList[0]) * 1000);

      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.$refs.audio2.play();
      }, (3 + this.audioTimeList[0]) * 1000); //间隔

      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, (3 + this.audioTimeList[0] + this.audioTimeList[1]) * 1000);

      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.$refs.audio3.play();
        this.startNumber("num3", this.extra_param_3[1], 2);
        this.loadChart4();
      }, (4 + this.audioTimeList[0] + this.audioTimeList[1]) * 1000); //间隔

      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, (4 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2]) * 1000);

      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.$refs.audio4.play();
        this.startNumber("num4", this.extra_param_4[1], 2);
        this.loadChart5();
      }, (5 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2]) * 1000); //间隔

      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, (5 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3]) * 1000);

      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.$refs.audio5.play();
        this.loadChart6();
      }, (6 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3]) * 1000); //间隔

      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, (6 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4]) * 1000);

      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        this.$refs.audio6.play();
      }, (7 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4]) * 1000);

      setTimeout(() => {
        $("#card7").addClass("animate__fadeOut");
      }, (7 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4] + this.audioTimeList[5]) * 1000);

      setTimeout(() => {
        $("#card7").addClass("none");
        $("#card8").removeClass("none");
        this.$refs.endVideo.play();
      }, (8 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4] + this.audioTimeList[5]) * 1000);
      // 总时间 94 + 3秒
      if (this.device_id) {
        setTimeout(() => {
          ApiServe.recordEnd({
            model_id: this.model_id,
            device_id: this.device_id,
            real: 1,
          });
        }, (this.recordtimeAll + 5) * 1000);
      }
    },
  },
};
</script>
<style scoped lang="less">
* {
  box-sizing: border-box;
  li {
    list-style: none;
  }
}
.max_box {
  background: url("../assets/img/bg.png") no-repeat center center;
  background-size: cover;
  font-size: 12px;
}

.logo-box {
  width: 78px;
  opacity: 1;
}
.card {
  background: none;
  color: #7e8090;
  font-size: 12px;
  overflow: hidden;
  padding: 133px 8px;
  box-sizing: border-box;
  .content_box {
    background: #fff;
    width: 100%;
    height: 533px;
    box-shadow: 0px 12px 32px rgba(52, 76, 92, 0.16);
    border-radius: 8px;
    border: 2px solid #e5e7ef;
    padding: 21px 13px;
    position: relative;
  }
}
.color_EF622E {
  color: #ef622e;
}

.color_gold {
  color: #f57f26;
}
.color_green {
  color: #439679;
}

.ab_center {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}
.num_width {
  display: inline-block;
  width: 65px;
  text-align: center;
}
.num_width2 {
  display: inline-block;
  width: 50px;
  text-align: center;
}
.card1_top_box {
  text-align: center;
  margin-top: 72px;
  width: 100%;
  > p:first-of-type {
    font-size: 32px;
    font-weight: bold;
    color: #3c3c3c;
    opacity: 1;
  }
  > p:nth-of-type(2) {
    font-size: 14px;
    background-color: #ef622e;
    border-radius: 13px;
    width: 105px;
    height: 27px;
    line-height: 27px;
    margin: 26px auto;
    text-align: center;
    color: #fff;
  }
  > p:nth-of-type(3) {
    color: #ef622e;
    font-weight: 500;
  }
}
@keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -114px, 0);
    transform: translate3d(0, -114px, 0);
    display: none;
  }
}
.rank_list {
  margin-top: 8px;
  width: 100%;
  margin-bottom: 22px;
  max-height: 285px;
  overflow: hidden;
  ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    animation: rowup 5s infinite;

    li {
      width: 23%;
      height: 53px;
      background: #3b4dd0;
      opacity: 1;
      border-radius: 4px;
      margin-bottom: 4px;
      text-align: center;
      padding-top: 8px;
      box-sizing: border-box;
      .text1 {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 4px;
      }
      .text2 {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
      }
    }
    .card-li-0 {
      opacity: 1;
    }
    .card-li-1 {
      opacity: 0.95;
    }
    .card-li-2 {
      opacity: 0.9;
    }
    .card-li-3 {
      opacity: 0.85;
    }
    .card-li-4 {
      opacity: 0.8;
    }
    .card-li-5 {
      opacity: 0.75;
    }
    .card-li-6 {
      opacity: 0.7;
    }
    .card-li-7 {
      opacity: 0.65;
    }
    .card-li-8 {
      opacity: 0.6;
    }
  }
}
.slider {
  font-size: 12px;
  color: #747271;
  display: flex;
  margin: auto;
  span {
    width: 15px;
    height: 17px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 12px;
    color: #747271;
    opacity: 1;
    margin: 0 5px;
  }
  div {
    width: 60px;
    height: 11px;
    background: #3b4dd0;
    opacity: 1;
  }
  div:first-of-type {
    opacity: 1;
  }
  div:nth-of-type(2) {
    opacity: 0.8;
  }
  div:nth-of-type(3) {
    opacity: 0.6;
  }
  div:nth-of-type(4) {
    opacity: 0.4;
  }
  div:nth-of-type(5) {
    opacity: 0.2;
  }
}
.tips {
  text-align: center;
  font-size: 11px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #878998;
  opacity: 1;
  position: absolute;
  bottom: 20px;
  width: 235px;
  margin-left: 36px;
}

#card6 {
  .desc_title2 {
    width: 81px;
    text-align: right;
  }
  .desc_title3 {
    float: right;
    width: 71px;
    text-align: center;
  }
  .desc_values_width {
    display: inline-block;
    width: 81px;
    text-align: right;
  }
  .desc_values_width1 {
    display: inline-block;
    width: 71px;
    text-align: center;
  }
}
#chart1,
#chart1_2 {
  height: 157px;
}
#chart2 {
  margin-top: 80px;
}
#chart2 {
  width: 100%;
  height: 400px;
}
#chart4 {
  width: 100%;
  height: 345px;
}
#chart5 {
  width: 100%;
  height: 345px;
}
#chart6 {
  width: 100%;
  height: 380px;
}

#card4 .title {
  margin-bottom: 15px;
}
#chart3_1,
#chart3_2,
#chart3_3 {
  margin-top: 10px;
  height: 300px;
  width: 100%;
}
#card6 .title .title_l {
  font-size: 16px;
  margin-top: 2px;
}
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .title_l {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 28px;
    color: #37393a;
    opacity: 1;
  }
  .title_1 {
    width: 170px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: #4d4d4d;
    opacity: 1;
  }
  .title_r_1 {
    width: 64px;
    height: 17px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 36px;
    color: #2d2d2d;
    opacity: 1;
    > span:nth-of-type(2) {
      margin: 0 16px;
    }
  }
}
#card3 .title,
#card6 .title {
  margin-bottom: 40px;
}
.desc {
  width: 100%;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 25px;
  padding: 9px 5px;
  color: #4d4d4d;
  opacity: 1;
  background: #f2f4fd;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.desc_title_wrapper {
  overflow: hidden;
  .desc_title1 {
    float: left;
    color: #646464;
    margin-left: 70px;
  }
  .desc_title2 {
    float: right;
    color: #646464;
    margin-right: 2px;
  }
}
.desc_title_wrapper7 {
  overflow: hidden;
  font-size: 10px;
  width: 100%;
  .desc_title1 {
    float: left;
    width: 20%;
    text-align: left;
  }
  .desc_title2 {
    float: left;
    width: 20%;
    text-align: right;
  }
  .desc_title3 {
    float: left;
    width: 20%;
    text-align: right;
  }
}
#card7 .title {
  margin-bottom: 27px;
}

.desc_values_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  .desc_values_item {
    width: 100%;
    line-height: 35px;
    text-align: right;
    padding-right: 13px;
    color: #f35c35;
    .right_width {
      width: 29px;
      display: inline-block;
      text-align: center;
    }
  }
}

.desc_values_wrapper7 {
  width: 100%;
  height: 100%;
  .desc_values_item7 {
    width: 100%;
    line-height: 40px;
  }
  .span_width {
    display: inline-block;
    text-align: right;
    font-size: 10px;
    width: 20%;
  }
  .span_width1 {
    display: inline-block;
    width: 20%;
    text-align: left;
    font-size: 12px;
  }
  .span_width2 {
    display: inline-block;
    width: 20%;
    text-align: right;
    font-size: 10px;
  }
}
#card6 .desc_values_item {
  line-height: 38px;
}

.card4_title {
  color: #000;
}
.card4_des {
  padding: 12px 14px;
  background: #dee4f7;
  color: #5766da;
  font-size: 14px;
  line-height: 24px;
}
.card5_aa,
.card5_qq {
  position: absolute;
  width: 60px;
  text-align: right;
  right: 4px;
  top: 0px;
  bottom: 0;
  p {
    height: 10%;
  }
}
.card5_aa {
  p {
    color: #676767;
  }
}
.card5_qq p span {
  margin-top: 16px;
  display: inline-block;
}
.card5_qq > p > span:first-of-type {
  width: 14px;
  height: 14px;
  vertical-align: top;
  overflow: hidden;
  margin-right: 4px;
  img {
    width: 100%;
    height: auto;
  }
}
.card7_des {
  line-height: 24px;
  font-size: 14px;
  padding: 12px 0;
  letter-spacing: 1px;
  span {
    color: #ff6600;
  }
}
.islu {
  color: #00b466;
}
.isred {
  color: #e54444;
}
</style>